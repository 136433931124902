<template>
  <div class="card">
    <div class="card-header border-0 pt-6 row">
      <div class="card-title row col">
        <div v-if="currentTab == 'tab1'" class="row col-10">
          <div
            class="
              col-lg-3 col-12
              d-flex
              align-items-center
              position-relative
              my-1
            "
          >
            <span class="svg-icon svg-icon-1 position-absolute ms-6">
              <inline-svg src="media/icons/duotune/general/gen021.svg" />
            </span>
            <input
              type="text"
              v-model="pendingListSearch"
              @input="pendingTableSearch()"
              class="form-control form-control-solid ps-15"
              placeholder="搜尋"
            />
          </div>
        </div>
        <div v-else-if="currentTab == 'tab2'" class="row col-10">
          <div
            class="
              col-lg-3 col-12
              d-flex
              align-items-center
              position-relative
              my-1
            "
          >
            <span class="svg-icon svg-icon-1 position-absolute ms-6">
              <inline-svg src="media/icons/duotune/general/gen021.svg" />
            </span>
            <input
              type="text"
              v-model="search"
              @input="tableSearch()"
              class="form-control form-control-solid ps-15"
              placeholder="搜尋"
            />
          </div>
          <div class="col-lg col-12 d-flex align-items-center overflow-auto">
            <el-date-picker
              v-model="value"
              @change="changeDate"
              class="ms-xxl-5 ms-xl-5 ms-0"
              type="datetimerange"
              start-placeholder="開始日期"
              end-placeholder="結束日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="col d-flex justify-content-end card-toolbar">
          <ul class="nav">
            <li class="nav-item">
              <a
                @click="currentTab = 'tab1'"
                class="
                  nav-link
                  btn btn-sm btn-color-muted btn-active btn-active-light-primary
                  fw-bolder
                  px-4
                  me-1
                  active
                "
                data-bs-toggle="tab"
                href="#kt_table_widget_5_tab_1"
                >未回覆</a
              >
            </li>
            <li class="nav-item">
              <a
                @click="currentTab = 'tab2'"
                class="
                  nav-link
                  btn btn-sm btn-color-muted btn-active btn-active-light-primary
                  fw-bolder
                  px-4
                  me-1
                "
                data-bs-toggle="tab"
                href="#kt_table_widget_5_tab_2"
                >所有</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="tab-content">
        <div class="tab-pane fade active show" id="kt_table_widget_5_tab_1">
          <div v-if="isPendingLoadind">
            <span
              class="spinner-grow spinner-grow"
              style="width: 1rem; height: 1rem"
              role="status"
              aria-hidden="true"
            ></span>
            <i style="font-size: 16px">正在載入...</i>
          </div>
          <Datatable
            :table-data="pendingList"
            :table-header="tableHeader"
            :enable-items-per-page-dropdown="true"
            :openTextEnd="false"
            :striped="true"
          >
            <template v-slot:cell-userName="{ row: tableData }">
              {{ tableData.userName }}
            </template>
            <template v-slot:cell-comment="{ row: tableData }">
              <div class="comment">
                {{ tableData.comment }}
              </div>
            </template>
            <template v-slot:cell-orderNumber="{ row: tableData }">
              <div
                @click="toOrderList(tableData)"
                class="text-hover-primary text-primary"
                style="cursor: pointer"
              >
                {{ tableData.orderNumber }}
              </div>
            </template>
            <template v-slot:cell-facilityName="{ row: tableData }">
              {{ tableData.facilityName }}
            </template>
            <template v-slot:cell-ratingUpdateTime="{ row: tableData }">
              {{ tableData.ratingUpdateTime }}
            </template>
            <template v-slot:cell-rating="{ row: tableData }">
              <el-rate
                v-model="tableData.rating"
                disabled
                show-score
                text-color="#ff9900"
                score-template="{value}"
              >
              </el-rate>
            </template>
            <template v-slot:cell-status="{ row: tableData }">
              <span v-if="tableData.status" style="color: green">已回覆</span>
              <template v-else>
                <span v-if="tableData.hasHistory" style="color: #f6c4c4f2"
                  >未回覆</span
                >
                <span v-else style="color: red">未回覆</span>
              </template>
            </template>
            <template v-slot:cell-operate="{ row: tableData }">
              <el-dropdown>
                <span
                  @mouseover="setCurrentItem(tableData)"
                  class="el-dropdown-link text-primary"
                >
                  功能選單<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      data-bs-toggle="modal"
                      data-bs-target="#replyWindow-modal"
                      >回覆評論</el-dropdown-item
                    >
                    <el-dropdown-item
                      data-bs-toggle="modal"
                      data-bs-target="#historyFeedback-modal"
                      >歷史紀錄</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </Datatable>
        </div>
        <div class="tab-pane fade" id="kt_table_widget_5_tab_2">
          <div v-if="isLoadind">
            <span
              class="spinner-grow spinner-grow"
              style="width: 1rem; height: 1rem"
              role="status"
              aria-hidden="true"
            ></span>
            <i style="font-size: 16px">正在載入...</i>
          </div>
          <Datatable
            :table-data="tableData"
            :table-header="tableHeader"
            :enable-items-per-page-dropdown="true"
            :openTextEnd="false"
            :striped="true"
          >
            <template v-slot:cell-userName="{ row: tableData }">
              {{ tableData.userName }}
            </template>
            <template v-slot:cell-comment="{ row: tableData }">
              <div class="comment">
                {{ tableData.comment }}
              </div>
            </template>
            <template v-slot:cell-orderNumber="{ row: tableData }">
              <div
                @click="toOrderList(tableData)"
                class="text-hover-primary text-primary"
                style="cursor: pointer"
              >
                {{ tableData.orderNumber }}
              </div>
            </template>
            <template v-slot:cell-facilityName="{ row: tableData }">
              {{ tableData.facilityName }}
            </template>
            <template v-slot:cell-ratingUpdateTime="{ row: tableData }">
              {{ tableData.ratingUpdateTime }}
            </template>
            <template v-slot:cell-rating="{ row: tableData }">
              <el-rate
                v-model="tableData.rating"
                disabled
                show-score
                text-color="#ff9900"
                score-template="{value}"
              >
              </el-rate>
            </template>
            <template v-slot:cell-status="{ row: tableData }">
              <span v-if="tableData.status" style="color: green">已回覆</span>
              <template v-else>
                <span v-if="tableData.hasHistory" style="color: #f6c4c4f2"
                  >未回覆</span
                >
                <span v-else style="color: red">未回覆</span>
              </template>
            </template>
            <template v-slot:cell-operate="{ row: tableData }">
              <el-dropdown>
                <span
                  @mouseover="setCurrentItem(tableData)"
                  class="el-dropdown-link text-primary"
                >
                  功能選單<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      data-bs-toggle="modal"
                      data-bs-target="#replyWindow-modal"
                      >回覆評論</el-dropdown-item
                    >
                    <el-dropdown-item
                      data-bs-toggle="modal"
                      data-bs-target="#historyFeedback-modal"
                      >歷史紀錄</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </Datatable>
        </div>
      </div>
    </div>
  </div>
  <!-- 回覆評論modal -->
  <div
    class="modal fade"
    id="replyWindow-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="replyWindow-exampleModalLabel">
            回饋評論
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ReplyWindow
            :feedbackData="currentItem"
            @onReply="setTableData()"
          ></ReplyWindow>
        </div>
      </div>
    </div>
  </div>
  <!-- 歷史紀錄modal -->
  <div
    class="modal fade"
    id="historyFeedback-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="historyFeedback-exampleModalLabel">
            歷史紀錄
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <HistoryFeedback :feedbackData="currentItem"></HistoryFeedback>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref, toRefs } from "vue";
import {
  getSpaceFeedback,
  getPendingFeedback,
} from "@/core/services/api/feedback";
import localstorage from "@/core/services/LocalstorageService";
import moment from "moment";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import { searchItems } from "@/core/utils/tableSearch";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import { getOrderByGuid } from "@/core/services/api/order";
import ReplyWindow from "./components/ReplyWindow.vue";
import HistoryFeedback from "./components/HistoryFeedback.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Datatable,
    ReplyWindow,
    HistoryFeedback,
  },
  setup() {
    const router = useRouter();
    const currentItem = reactive({
      userName: "",
      comment: "",
      feedback: {
        guid: "",
        reply: "",
        ratingUpdateTime: "",
        replyUpdateTime: "",
      },
    });
    const facility: any = reactive([]);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const search = ref<string>("");
    const pendingListSearch = ref<string>("");
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const tableHeader = ref([
      { key: "userName", name: "用戶名", sortable: true },
      { key: "comment", name: "最新評論", sortable: true },
      { key: "rating", name: "評分", sortable: true },
      { key: "orderNumber", name: "訂單編號", sortable: true },
      { key: "facilityName", name: "設施", sortable: true },
      { key: "ratingUpdateTime", name: "評論更新時間", sortable: true },
      { key: "status", name: "狀態", sortable: true },
      { key: "operate", name: "操作" },
    ]);
    const isPendingLoadind = ref(false);
    const isLoadind = ref(false);
    const datePicker = reactive({
      value: [
        moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        moment().subtract().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      ],
    });
    const currentTab = ref("tab1");
    const pendingList: any = reactive([]);
    const initPendingList: any = reactive([]);

    const changeDate = async () => {
      await setTableData();
    };

    const tableSearch = () => {
      tableData.splice(0, tableData.length, ...initTableData);
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, tableData)
      );
    };

    const pendingTableSearch = () => {
      pendingList.splice(0, pendingList.length, ...initPendingList);
      pendingList.splice(
        0,
        pendingList.length,
        ...searchItems(pendingListSearch.value, pendingList)
      );
    };

    // 取得設施名稱
    const getFacilityName = async (guid) => {
      // let facility = await getFacilityBySpace(currentSpaceGuid!);
      let facilityName = facility.filter((f) => f.facility.guid == guid)[0]
        .facility.name;
      return facilityName;
    };

    // 根據訂單guid取得訂單編號
    const getOrderNumberByguid = async (guid) => {
      return (await getOrderByGuid(guid)).order.runningNumber;
    };

    // 根據訂單guid取得訂單
    const getOrderByguid = async (guid) => {
      return await getOrderByGuid(guid);
    };

    // 驗證是否已回覆
    const verifyIsReplied = (feedback) => {
      // 用戶評論時間
      let ratingUpdateTime = moment(feedback.ratingUpdateTime);
      // 空間回覆時間
      let replyUpdateTime = moment(feedback.replyUpdateTime);

      return ratingUpdateTime.isBefore(replyUpdateTime);
    };

    // toOrderList
    const toOrderList = async (item) => {
      router.push({
        name: "orderList",
        params: { orderNumber: item.orderNumber },
      });
    };

    const setCurrentItem = (item) => {
      Object.assign(currentItem, item);
    };

    const setTableData = async () => {
      isLoadind.value = true;
      let request = {
        beginTime: getUtcTimeString(datePicker.value[0]),
        endTime: getUtcTimeString(datePicker.value[1]),
      };
      let feetback = await getSpaceFeedback(currentSpaceGuid, request);
      let feetbackList: any = [];
      for (const o of feetback) {
        feetbackList.push({
          orderGuid: o.order,
          feedback: o,
          orderNumber: await getOrderNumberByguid(o.order),
          comment: o.comment,
          facilityName: await getFacilityName(o.facility),
          tableCreateTime: getLocalTimeString(o.createTime),
          rating: o.rating,
          userName: o.userName,
          status: verifyIsReplied(o),
          ratingUpdateTime: getLocalTimeString(o.ratingUpdateTime),
          hasHistory: o.createTime !== o.ratingUpdateTime,
        });
      }
      feetbackList.sort((a, b) =>
        moment(b.ratingUpdateTime).diff(moment(a.ratingUpdateTime))
      );
      tableData.splice(0, tableData.length, ...feetbackList);
      initTableData.splice(0, initTableData.length, ...feetbackList);
      isLoadind.value = false;
    };

    const setCurrentTab = (event) => {
      console.log("event", event.target.href);
    };

    // 取得所有未回覆
    const handleGetPendingFeedback = async () => {
      return await getPendingFeedback(currentSpaceGuid);
    };

    // 設置未回覆列表
    const setPendingList = async () => {
      isPendingLoadind.value = true;
      let pendingFeedback: any = await handleGetPendingFeedback();
      pendingList.splice(0);
      for (const o of pendingFeedback) {
        pendingList.push({
          orderGuid: o.order,
          feedback: o,
          orderNumber: await getOrderNumberByguid(o.order),
          comment: o.comment,
          facilityName: await getFacilityName(o.facility),
          tableCreateTime: getLocalTimeString(o.createTime),
          rating: o.rating,
          userName: o.userName,
          status: verifyIsReplied(o),
          ratingUpdateTime: getLocalTimeString(o.ratingUpdateTime),
          hasHistory: o.createTime != o.ratingUpdateTime,
        });
      }
      initPendingList.splice(0, initPendingList.length, ...pendingList);
      isPendingLoadind.value = false;
      console.log("pendingList", pendingList);
    };

    const init = async () => {
      facility.splice(
        0,
        facility.length,
        ...(await getFacilityBySpace(currentSpaceGuid!))
      );
      await setTableData();
      await setPendingList();
    };
    init();

    return {
      ...toRefs(datePicker),
      tableData,
      tableSearch,
      tableHeader,
      search,
      isLoadind,
      changeDate,
      setCurrentItem,
      currentItem,
      setTableData,
      toOrderList,
      setCurrentTab,
      currentTab,
      pendingList,
      initPendingList,
      isPendingLoadind,
      pendingListSearch,
      pendingTableSearch,
    };
  },
});
</script>

<style scoped>
.comment {
  max-width: 400px;
}
</style>