import ApiService from "@/core/services/ApiService";
import { OrderExtend } from "@/core/services/responsesModel/orderExtend"

export const orderStatusDictionary = {
    UnAuthorize: '未授權',
    Authorize: '已授權',
    Cancel: '取消',
    Abort: '中止',
    Finish: '已結束'
}

export const orderModeDictionary = {
    FreeSolution: '免費方案',
    Standard: '標準',
    Manual: '手動處理',
    Contract: '合約'
}

export const paymentType = {
    Free: '免費方案',
    Tappay: 'Tappay付款',
    Manual: '手動處理',
    SpacesPoint: '點數付款'
}

// 查詢空間與指定時間內預定的訂單
export function getOrderBySpace(request): Promise<Array<OrderExtend>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Order/QueryBySpace`, request).then(res => {
            resolve(res.data.orders)
        }).catch(err => {
            reject(err)
        })
    })
}

// 根據guid取得訂單
export function getOrderByGuid(orderGuid): Promise<OrderExtend> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Order/${orderGuid}`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 線下付款核對
export function manualPaymentCheck(spaceGuid,paymentGuid,request) {
    return new Promise((resolve, reject) => {
        ApiService.patch(`Space/${spaceGuid}/Payment/${paymentGuid}/Manual/Pay`,request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}