<template>
  <div class="container">
    <div>
      <div class="topData ">
        <div class="ms-2">用戶:</div>
        <div class="ms-2">{{ props.feedbackData.userName }}</div>
        <div class="ms-4">評分:</div>
        <div>
          <el-rate
            v-model="props.feedbackData.feedback.rating"
            disabled
            show-score
            text-color="#ff9900"
            score-template="{value}"
          >
          </el-rate>
        </div>
      </div>
      <div class="ms-2">
        <div class="messageBox alert alert-info" role="alert">
          {{ props.feedbackData.comment }}
        </div>
      </div>
      <div v-if="verifyIsReplied()">
        <div class="row ms-2 justify-content-end">
          <div class="alert alert-warning col- messageBox" role="alert">
            {{ reply }}
          </div>
        </div>
      </div>
      <hr />

      <div class="row">
        <div class="col-12">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4 }"
            placeholder="請輸入回覆"
            v-model="enterReply"
          >
          </el-input>
        </div>
      </div>
      <div v-if="verifyIsReplied()" class="row justify-content-end mt-2">
        <button
          class="col-2 me-3 btn btn-warning"
          type="button"
          @click="updateReply"
        >
          更新回覆
        </button>
      </div>
      <div v-else class="row justify-content-end mt-2">
        <button
          class="col-2 me-3 btn btn-primary"
          type="button"
          @click="replyComments"
        >
          回覆評論
        </button>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, ref, watch } from "vue";
import localstorage from "@/core/services/LocalstorageService";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { replyFeedback,updateFeedback } from "@/core/services/api/feedback";

export default defineComponent({
  props: ["feedbackData"],
  setup(props,{emit}) {
    const enterReply = ref("");
    const reply = ref("");
    const currentFeedback = {};
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const onReply = ref(false);

    watch(
      props,
      () => {
        reply.value = JSON.parse(
          JSON.stringify(props.feedbackData.feedback.reply)
        );
        onReply.value = false;
      },
      { immediate: true, deep: true }
    );

    // 驗證是否已回覆
    const verifyIsReplied = () => {
      // 用戶評論時間
      let ratingUpdateTime = moment(
        props.feedbackData.feedback.ratingUpdateTime
      );
      // 空間回覆時間
      let replyUpdateTime = moment(props.feedbackData.feedback.replyUpdateTime);
      console.log("props.feedbackData",props.feedbackData)
      return ratingUpdateTime.isBefore(replyUpdateTime) || onReply.value;
    };

    // 回覆評論
    const replyComments = () => {
      if (enterReply.value !== "") {
        Swal.fire({
          title: "確定要回覆評論嗎?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "確定!",
          cancelButtonText: "取消",
        }).then((result) => {
          if (result.isConfirmed) {
            let request = {
              reply: enterReply.value,
            };
            console.log("request",request)
            console.log("props.feedbackData",props.feedbackData)
            if(props.feedbackData.feedback.reply==""){
              replyFeedback(currentSpaceGuid, props.feedbackData.feedback.guid, request).then(
              () => {
                reply.value = JSON.parse(JSON.stringify(enterReply.value));
                enterReply.value = "";
                onReply.value = true;
                emit("onReply");
              }
            );
            }else{
             updateFeedback(currentSpaceGuid, props.feedbackData.feedback.guid, request).then(
              () => {
                reply.value = JSON.parse(JSON.stringify(enterReply.value));
                enterReply.value = "";
                onReply.value = true;
                emit("onReply");
              }
            );
            }
            
          }
        });
      }
    };

    // 更新回覆
    const updateReply = () => {
      if (enterReply.value !== "") {
        Swal.fire({
          title: "確定要更新您的回覆嗎?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "確定!",
          cancelButtonText: "取消",
        }).then(async (result) => {
          if (result.isConfirmed) {
            let request = {
              reply: enterReply.value,
            };
            console.log("request",request)
            await updateFeedback(currentSpaceGuid, props.feedbackData.feedback.guid, request).then(
              () => {
                reply.value = JSON.parse(JSON.stringify(enterReply.value));
                enterReply.value = "";
                emit("onReply");
              }
            );
          }
        });
      }
    };

    return {
      props,
      verifyIsReplied,
      replyComments,
      updateReply,
      enterReply,
      reply,
    };
  },
});
</script>

<style scoped>
.commentTitle {
  font-size: 14px;
  font-weight: 600;
}
.messageBox {
  max-width: 50%;
}

.topData {
  display: flex;
  margin-bottom: 10px;
}
</style>