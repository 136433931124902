import ApiService from "@/core/services/ApiService";
import { Feedback } from "@/core/services/responsesModel/feedback"

// 空間根據時間取得使用回饋
export function getSpaceFeedback(spaceGuid, request): Promise<Array<Feedback>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/Feedback/Filter`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 空間回覆使用回饋
export function replyFeedback(spaceGuid, feedbackGuid, request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/Feedback/${feedbackGuid}/Reply`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 空間更新回覆
export function updateFeedback(spaceGuid, feedbackGuid, request) {
    return new Promise((resolve, reject) => {
        ApiService.patch(`Space/${spaceGuid}/Feedback/${feedbackGuid}/Reply`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 取得所有未回覆 
export function getPendingFeedback(spaceGuid) {
    return new Promise((resolve, reject) => {
        ApiService.get(`Space/${spaceGuid}/Feedback/Pending`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}