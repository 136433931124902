
import { defineComponent, ref, watch } from "vue";
import localstorage from "@/core/services/LocalstorageService";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { replyFeedback,updateFeedback } from "@/core/services/api/feedback";

export default defineComponent({
  props: ["feedbackData"],
  setup(props,{emit}) {
    const enterReply = ref("");
    const reply = ref("");
    const currentFeedback = {};
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const onReply = ref(false);

    watch(
      props,
      () => {
        reply.value = JSON.parse(
          JSON.stringify(props.feedbackData.feedback.reply)
        );
        onReply.value = false;
      },
      { immediate: true, deep: true }
    );

    // 驗證是否已回覆
    const verifyIsReplied = () => {
      // 用戶評論時間
      let ratingUpdateTime = moment(
        props.feedbackData.feedback.ratingUpdateTime
      );
      // 空間回覆時間
      let replyUpdateTime = moment(props.feedbackData.feedback.replyUpdateTime);
      console.log("props.feedbackData",props.feedbackData)
      return ratingUpdateTime.isBefore(replyUpdateTime) || onReply.value;
    };

    // 回覆評論
    const replyComments = () => {
      if (enterReply.value !== "") {
        Swal.fire({
          title: "確定要回覆評論嗎?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "確定!",
          cancelButtonText: "取消",
        }).then((result) => {
          if (result.isConfirmed) {
            let request = {
              reply: enterReply.value,
            };
            console.log("request",request)
            console.log("props.feedbackData",props.feedbackData)
            if(props.feedbackData.feedback.reply==""){
              replyFeedback(currentSpaceGuid, props.feedbackData.feedback.guid, request).then(
              () => {
                reply.value = JSON.parse(JSON.stringify(enterReply.value));
                enterReply.value = "";
                onReply.value = true;
                emit("onReply");
              }
            );
            }else{
             updateFeedback(currentSpaceGuid, props.feedbackData.feedback.guid, request).then(
              () => {
                reply.value = JSON.parse(JSON.stringify(enterReply.value));
                enterReply.value = "";
                onReply.value = true;
                emit("onReply");
              }
            );
            }
            
          }
        });
      }
    };

    // 更新回覆
    const updateReply = () => {
      if (enterReply.value !== "") {
        Swal.fire({
          title: "確定要更新您的回覆嗎?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "確定!",
          cancelButtonText: "取消",
        }).then(async (result) => {
          if (result.isConfirmed) {
            let request = {
              reply: enterReply.value,
            };
            console.log("request",request)
            await updateFeedback(currentSpaceGuid, props.feedbackData.feedback.guid, request).then(
              () => {
                reply.value = JSON.parse(JSON.stringify(enterReply.value));
                enterReply.value = "";
                emit("onReply");
              }
            );
          }
        });
      }
    };

    return {
      props,
      verifyIsReplied,
      replyComments,
      updateReply,
      enterReply,
      reply,
    };
  },
});
