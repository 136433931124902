
import { defineComponent, reactive, ref, toRefs } from "vue";
import {
  getSpaceFeedback,
  getPendingFeedback,
} from "@/core/services/api/feedback";
import localstorage from "@/core/services/LocalstorageService";
import moment from "moment";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import { searchItems } from "@/core/utils/tableSearch";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import { getOrderByGuid } from "@/core/services/api/order";
import ReplyWindow from "./components/ReplyWindow.vue";
import HistoryFeedback from "./components/HistoryFeedback.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Datatable,
    ReplyWindow,
    HistoryFeedback,
  },
  setup() {
    const router = useRouter();
    const currentItem = reactive({
      userName: "",
      comment: "",
      feedback: {
        guid: "",
        reply: "",
        ratingUpdateTime: "",
        replyUpdateTime: "",
      },
    });
    const facility: any = reactive([]);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const search = ref<string>("");
    const pendingListSearch = ref<string>("");
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const tableHeader = ref([
      { key: "userName", name: "用戶名", sortable: true },
      { key: "comment", name: "最新評論", sortable: true },
      { key: "rating", name: "評分", sortable: true },
      { key: "orderNumber", name: "訂單編號", sortable: true },
      { key: "facilityName", name: "設施", sortable: true },
      { key: "ratingUpdateTime", name: "評論更新時間", sortable: true },
      { key: "status", name: "狀態", sortable: true },
      { key: "operate", name: "操作" },
    ]);
    const isPendingLoadind = ref(false);
    const isLoadind = ref(false);
    const datePicker = reactive({
      value: [
        moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        moment().subtract().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      ],
    });
    const currentTab = ref("tab1");
    const pendingList: any = reactive([]);
    const initPendingList: any = reactive([]);

    const changeDate = async () => {
      await setTableData();
    };

    const tableSearch = () => {
      tableData.splice(0, tableData.length, ...initTableData);
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, tableData)
      );
    };

    const pendingTableSearch = () => {
      pendingList.splice(0, pendingList.length, ...initPendingList);
      pendingList.splice(
        0,
        pendingList.length,
        ...searchItems(pendingListSearch.value, pendingList)
      );
    };

    // 取得設施名稱
    const getFacilityName = async (guid) => {
      // let facility = await getFacilityBySpace(currentSpaceGuid!);
      let facilityName = facility.filter((f) => f.facility.guid == guid)[0]
        .facility.name;
      return facilityName;
    };

    // 根據訂單guid取得訂單編號
    const getOrderNumberByguid = async (guid) => {
      return (await getOrderByGuid(guid)).order.runningNumber;
    };

    // 根據訂單guid取得訂單
    const getOrderByguid = async (guid) => {
      return await getOrderByGuid(guid);
    };

    // 驗證是否已回覆
    const verifyIsReplied = (feedback) => {
      // 用戶評論時間
      let ratingUpdateTime = moment(feedback.ratingUpdateTime);
      // 空間回覆時間
      let replyUpdateTime = moment(feedback.replyUpdateTime);

      return ratingUpdateTime.isBefore(replyUpdateTime);
    };

    // toOrderList
    const toOrderList = async (item) => {
      router.push({
        name: "orderList",
        params: { orderNumber: item.orderNumber },
      });
    };

    const setCurrentItem = (item) => {
      Object.assign(currentItem, item);
    };

    const setTableData = async () => {
      isLoadind.value = true;
      let request = {
        beginTime: getUtcTimeString(datePicker.value[0]),
        endTime: getUtcTimeString(datePicker.value[1]),
      };
      let feetback = await getSpaceFeedback(currentSpaceGuid, request);
      let feetbackList: any = [];
      for (const o of feetback) {
        feetbackList.push({
          orderGuid: o.order,
          feedback: o,
          orderNumber: await getOrderNumberByguid(o.order),
          comment: o.comment,
          facilityName: await getFacilityName(o.facility),
          tableCreateTime: getLocalTimeString(o.createTime),
          rating: o.rating,
          userName: o.userName,
          status: verifyIsReplied(o),
          ratingUpdateTime: getLocalTimeString(o.ratingUpdateTime),
          hasHistory: o.createTime !== o.ratingUpdateTime,
        });
      }
      feetbackList.sort((a, b) =>
        moment(b.ratingUpdateTime).diff(moment(a.ratingUpdateTime))
      );
      tableData.splice(0, tableData.length, ...feetbackList);
      initTableData.splice(0, initTableData.length, ...feetbackList);
      isLoadind.value = false;
    };

    const setCurrentTab = (event) => {
      console.log("event", event.target.href);
    };

    // 取得所有未回覆
    const handleGetPendingFeedback = async () => {
      return await getPendingFeedback(currentSpaceGuid);
    };

    // 設置未回覆列表
    const setPendingList = async () => {
      isPendingLoadind.value = true;
      let pendingFeedback: any = await handleGetPendingFeedback();
      pendingList.splice(0);
      for (const o of pendingFeedback) {
        pendingList.push({
          orderGuid: o.order,
          feedback: o,
          orderNumber: await getOrderNumberByguid(o.order),
          comment: o.comment,
          facilityName: await getFacilityName(o.facility),
          tableCreateTime: getLocalTimeString(o.createTime),
          rating: o.rating,
          userName: o.userName,
          status: verifyIsReplied(o),
          ratingUpdateTime: getLocalTimeString(o.ratingUpdateTime),
          hasHistory: o.createTime != o.ratingUpdateTime,
        });
      }
      initPendingList.splice(0, initPendingList.length, ...pendingList);
      isPendingLoadind.value = false;
      console.log("pendingList", pendingList);
    };

    const init = async () => {
      facility.splice(
        0,
        facility.length,
        ...(await getFacilityBySpace(currentSpaceGuid!))
      );
      await setTableData();
      await setPendingList();
    };
    init();

    return {
      ...toRefs(datePicker),
      tableData,
      tableSearch,
      tableHeader,
      search,
      isLoadind,
      changeDate,
      setCurrentItem,
      currentItem,
      setTableData,
      toOrderList,
      setCurrentTab,
      currentTab,
      pendingList,
      initPendingList,
      isPendingLoadind,
      pendingListSearch,
      pendingTableSearch,
    };
  },
});
