import ApiService from "@/core/services/ApiService";

// 取得回饋的更新紀錄
export function getFeedbackUpdateHistory(feedbackGuid) {
    return new Promise((resolve, reject) => {
        ApiService.get(`Feedback/${feedbackGuid}/FeedbackUpdateHistory`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}