<template>
  <div v-if="isNoHistory()">
    <i>無歷史紀錄</i>
  </div>
  <div v-else>
    <Datatable
      :table-data="tableData"
      :table-header="tableHeader"
      :enable-items-per-page-dropdown="true"
      :openTextEnd="false"
      :striped="true"
    >
      <template v-slot:cell-ratingUpdateTime="{ row: tableData }">
        {{ tableData.ratingUpdateTime }}
      </template>
      <template v-slot:cell-comment="{ row: tableData }">
        {{ tableData.comment }}
      </template>

      <template v-slot:cell-rating="{ row: tableData }">
        <el-rate
          v-model="tableData.rating"
          disabled
          show-score
          text-color="#ff9900"
          score-template="{value}"
        >
        </el-rate>
      </template>
      <template v-slot:cell-replyUpdateTime="{ row: tableData }">
        {{ replyInformation(tableData.feedback, tableData.replyUpdateTime) }}
      </template>
      <template v-slot:cell-reply="{ row: tableData }">
        {{ replyInformation(tableData.feedback, tableData.reply) }}
      </template>
    </Datatable>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref, watch } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getFeedbackUpdateHistory } from "@/core/services/api/feedbackUpdateHistory";
import localstorage from "@/core/services/LocalstorageService";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import moment from "moment";

export default defineComponent({
  components: {
    Datatable,
  },
  props: ["feedbackData"],
  setup(props, { emit }) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const tableData: any = reactive([]);
    const tableHeader = ref([
      { key: "ratingUpdateTime", name: "用戶評論日期", sortable: true },
      { key: "comment", name: "用戶評論", sortable: true },
      { key: "rating", name: "用戶評分", sortable: true },
      { key: "replyUpdateTime", name: "空間回覆時間", sortable: true },
      { key: "reply", name: "空間回覆", sortable: true },
    ]);

    // 回覆訊息
    const replyInformation = (row, show) => {
      // 用戶評論時間
      let ratingUpdateTime = moment(row.ratingUpdateTime);
      // 空間回覆時間
      let replyUpdateTime = moment(row.replyUpdateTime);

      return ratingUpdateTime.isBefore(replyUpdateTime) ? show : "無回覆";
    };

    const setTableData = async () => {
      let feedback: any = await getFeedbackUpdateHistory(
        props.feedbackData.feedback.guid
      );
      tableData.splice(0);
      for (const o of feedback) {
        tableData.push({
          feedback: o,
          ratingUpdateTime: getLocalTimeString(o.ratingUpdateTime),
          comment: o.comment,
          rating: o.rating,
          replyUpdateTime: getLocalTimeString(o.replyUpdateTime),
          reply: o.reply,
        });
      }
      tableData.sort((a, b) =>moment(b.ratingUpdateTime).diff(moment(a.ratingUpdateTime)));
    };

    // 是否沒有歷史回復
    const isNoHistory = () => {
      return tableData.length <= 0;
    };

    watch(
      props,
      () => {
        setTableData();
      },
      { deep: true }
    );

    return {
      tableData,
      tableHeader,
      replyInformation,
      isNoHistory,
    };
  },
});
</script>

<style>
</style>